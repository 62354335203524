import me from './me.jpg'
import stickman from './stickman.svg'
import me_profile from './me_profile.jpg'
const Photo = () => {
    return (
        <div className="photo">
            <img src={me_profile} className="me" alt="me" />
        </div>
    );
}
export default Photo;