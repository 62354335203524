import SocialMedia from "./SocailMedia";
import ContactForm from "./ContactForm";
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'


const Contacts = () => {

    return (
        <div className="contacts" id="contacts">
            <h1>Get in touch</h1>
            <p>Currently, I am a full-time student, so at this moment I am more interested in a part time job with the prospect of further cooperation. Feel free to contact me via <span data-tooltip-id="email" data-tooltip-content="mamaev.g2001@gmail.com" data-tooltip-place="bottom" className="blue" >email</span>:</p>
            <ContactForm />
            <p className="or">or you can try to reach out to me on social media:</p>
            <SocialMedia />
            <Tooltip opacity="0.6" id="email" clickable />
            {/* <Link className='nav_but normal_link'id='link7'>Show the form</Link> */}
        </div>
        
    );
}
export default Contacts;