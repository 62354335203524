import Project from "./Project";
import project1 from './project1.JPG'
import project2 from './project2.png'
import project4 from './project4.jpg'
import project5 from './project5.jpg'
const MyProjects = () => {
    const name1 = 'Reporting lost and found animals';
    // const name2 = 'Simple RPG engine';
    const name2 = 'Web application testing';
    const name3 = 'Simulation of Smart Home';
    const img1 = project1;
    const img2 = project5;
    const img3 = project4;
    const description1 = 'The application allows the user to view lists of lost and found pets. Registered users can also post announcements about lost or found pets.';
    // const description2 = 'Game engine allows the user to run and play levels described by external files. In the game, the player will control a hero who will go through different levels collecting items and fighting monsters.';
    //TODO
    const description2 = 'The "Web Application Testing" semestral project is centered around the systematic and comprehensive testing of a web application, ensuring its functionality, reliability, and security.';

    const description3 = 'The application performs a virtual simulation of an intelligent house, where we simulate the operation of the household, use individual devices of the house and evaluate the usage, consumption, free and working time of individual people.';
    const tags1 = 'HTML, CSS, PHP, JavaScript';
    // const tags2 = 'Java, JavaFX, Maven, JUnit';
    const tags2 = 'Testing analysis, Selenium, Java';
    const tags3 = 'Java, design patterns';
    const github1 = 'https://github.com/mamaegeo/Lost-and-Found';
    const github2 = 'https://github.com/mamaegeo/TS1';
    const github3 = 'https://gitlab.fel.cvut.cz/mamaegeo/omo_smarthome';
    const web1 = 'https://georgemamaev.site/ZWA/SM_ZWA.php';
    const web2 = "https://github.com/mamaegeo/TS1/blob/main/documentation.pdf"

    return (
        <div className="myProjects" id="myProjects">
            <div className="title">
                <h1>My projects</h1>
            </div>
            <Project name={name1} description={description1} img={img1} tags={tags1} github={github1} web={web1} />
            <Project name={name2} description={description2} img={img2} tags={tags2} github={github2} web={web2} />
            <Project name={name3} description={description3} img={img3} tags={tags3} github={github3} web={"none"} />
        </div>
    );
}
export default MyProjects;