import { HashLink as Link} from 'react-router-hash-link';
import resume from "./resume.pdf";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Navbar = () => {

    const showToastMessage = () => {
        toast.info('Blog is not available yet', {
            position: toast.POSITION.BOTTOM_CENTER
        });
    };
    // function pushMenuButton(){
    //     const links = document.querySelectorAll('.normal_link');
    //     links.forEach(link => {
    //         if(!link.className.includes("responsive")) {
    //             link.style.display = "block";
    //             link.className += " responsive";
    //         } else {
    //             link.style.display = "none";
    //             if(link.className.includes("nav_but")) link.className = "nav_but normal_link";
    //             if(link.className.includes("underline-anim")) link.className = "underline-anim normal_link";
    //         }
    //       });
        
    // }
    return (
        <nav className="navbar">
            <h1>Georgii Mamaev</h1>
            <div className="links" id='links'>
                {/* <Link className='menu_nav' smooth onClick={pushMenuButton}>Menu</Link> */}
                <Link className='underline-anim normal_link' id='link1' smooth to='#app'>Home</Link>
                <Link className='underline-anim normal_link' id='link2' smooth to='#aboutMe'>About me</Link>
                <Link className='underline-anim normal_link' id='link3' smooth to='#skills'>Skills</Link>
                <Link className='underline-anim normal_link' id='link4' smooth to='#myProjects'>Projects</Link>
                <Link className='underline-anim normal_link' id='link5' smooth to='#contacts'>Contacts</Link>
                <Link className='nav_but normal_link' target="_blank" id='link7' smooth to={resume}>Resume</Link>
                {/* <Link className='nav_but normal_link' id='link7' smooth to='#blog' onClick={showToastMessage}>My blog</Link> */}
                <ToastContainer />
            </div>
        </nav>
    );
}
export default Navbar;
