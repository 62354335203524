import OtherProject from "./OtherProject";
const OtherProjects = (
) => {
    const name1 = 'Simple RPG engine';
    const name2 = 'Analysis of stress factors among students ';
    // const name3 = 'Language courses "Cherry"';
    const name3 = 'Language learning app "Cherry"';
    const name4 = 'Reservation system';
    const name5 = 'Personal blog';
    const name6 = 'Resume website';
    const tags1 = 'Java, JavaFX, Maven, JUnit';
    const tags2 = 'PowerBI, Excel, Python';
    const tags3 = 'DJango, Python, WIP';
    const tags4 = 'Java, SpringBoot, Hibernate';
    const tags5 = 'JS, React, Python';
    const tags6 = 'JS, React, Node.js';
    const github1 = 'https://gitlab.fel.cvut.cz/B222_B0B36PJV/mamaegeo/-/wikis/home';
    const github2 = 'https://github.com/mamaegeo/ZDA';
    const github3 = 'https://github.com/mamaegeo/Cherry';
    const github4 = 'https://www.github.com/';
    const github5 = 'https://www.github.com/';
    const github6 = 'https://github.com/mamaegeo/Resume';
    const web1 = "none";
    const web2 = github2;
    const web3 = github3;
    const web4 = "none";
    const web5 = github5;
    const web6 = "https://georgemamaev.site/";
    return (
        <div className="otherProjects">
            <div className="title">
                <h1>Other projects</h1>
            </div>
            <div className="project_grid">
                <div className="project_grid_column project_grid_column_l">
                <OtherProject name={name6} tags={tags6} github={github6} web={web6} />
                <OtherProject name={name3} tags={tags3} github={github3} web={web3} />
                {/* <OtherProject name={name5} tags={tags5} github={github5} web={web5} /> */}
                </div>
                <div className="project_grid_column project_grid_column_r">
                <OtherProject name={name1} tags={tags1} github={github1} web={web1} />
                <OtherProject name={name2} tags={tags2} github={github2} web={web2} />
                {/* <OtherProject name={name4} tags={tags4} github={github4} web={web4} /> */}
                </div>
            </div>
            
            
        </div>
    );
}
export default OtherProjects;