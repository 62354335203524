import Photo from "./Photo";
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'

const AboutMe = () => {
    return (
        <div className="aboutMeContainer" id="aboutMe">
             <div className="aboutMe">
                <div className="aboutmeContent">
                <h1>About me</h1>
                <p>Hello, my name is <span data-tooltip-id="email" data-tooltip-content="or just Gosha/George" data-tooltip-place="top" className="blue" >Georgii Mamaev</span>, and currently I am studying information technologies at the Faculty of Electrical Engineering of the CTU in Prague.</p>
                <p>At the moment, I am looking for a part-time job within the IT industry.</p>
                {/* One day, I want to become a software engineer, that is why I am currently focused on learnging how to design, develop and test software applications */}
                <p>I have a very strong desire to help others, so I would be very happy to use my knowledge of various technologies in projects that aim to help people.</p>
                <p>In my free time, I enjoy doing sports, my favorite one is basketball, and I'm pretty good at it. I also like watching movies and series, listening to music and learning foreign languages.</p>
                </div>
                <Tooltip opacity="0.6" id="email" clickable />
            </div>
            <Photo />
        </div>
    );
}
export default AboutMe;