import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';

const ContactForm = () => {
  const toastContactFormError = (text) => {
    toast.error(text, {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  const toastContactFormSuccess = () => {
    toast.success('Email sent successfully', {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    email: '',
    message: '',
    attachment: null,
  });

  const [errors, setErrors] = useState({});

  // const [file, setFile] = useState(null);

  const handleChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0]; // Get the selected file
  
    // Check if a file is selected
    if (file) {
      setFormData((prevData) => ({
        ...prevData,
        attachment: file, // Set attachment in formData
      }));
  
      const fileName = file.name;
      const fileTextElement = document.querySelector('.file-name-text');
      fileTextElement.textContent = fileName;
    }
  };
  const handleFileButtonClick = (e) => {
    e.preventDefault();
    document.getElementById('attachment').click();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('http://localhost:3001/send-email', formData);
      // tbd sending file
      // Handle success, e.g., show a success message to the user
      console.log('Email sent successfully:', response.data);
      toastContactFormSuccess();
      setFormData({
        name: '',
        surname: '',
        email: '',
        message: '',
        attachment: null,
      });
      // setFile(null);
      const fileTextElement = document.querySelector('.file-name-text');
      fileTextElement.textContent = 'No file selected';
    } catch (error) {
      // Handle error, e.g., show an error message to the user
      if (axios.isAxiosError(error)) {
        // Axios-specific error (e.g., network issues, 4xx, 5xx status codes)
        if (error.response) {
          // Server responded with a non-2xx status code
          if (error.response.status === 400) {
            // Validation errors received from the server
            if (error.response.data.errors.name) toastContactFormError(error.response.data.errors.name);
            if (error.response.data.errors.surname) toastContactFormError(error.response.data.errors.surname);
            if (error.response.data.errors.email) toastContactFormError(error.response.data.errors.email);
            if (error.response.data.errors.message) toastContactFormError(error.response.data.errors.message);
            setErrors(error.response.data.errors);
          } else {
            // Other types of server errors
            toastContactFormError('Server error:' + error.response.data.message);
            console.error('Server error:', error.response.data.message);
          }
        } else {
          // Network issues or request never made it to the server
          toastContactFormError('Network error:' + error.message);
          console.error('Network error:', error.message);
        }
      } else {
        // Non-Axios error (e.g., unexpected errors)
        console.error('Unexpected error:', error.message);
      }
    }
  };

  return (
    <form className="contact_form" action="http://localhost:3001/send-email" method="POST" onSubmit={handleSubmit}>
      <div className="two_fields_row">
        <label className="contact_form_row">
          Name <input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Your name.." pattern="^[A-ZÁČĎÉĚÍŇÓŘŠŤÚŮÝŽ][a-záčďéěíňóřšťúůýž]*$" maxLength="20" title="Letters only, first letter must be capital" required/>
        </label>
        <label className="contact_form_row">
          Surname <input type="text" name="surname" value={formData.surname} onChange={handleChange} placeholder="Your surname.." pattern="^[A-ZÁČĎÉĚÍŇÓŘŠŤÚŮÝŽ][a-záčďéěíňóřšťúůýž]*$" maxLength="20" title="Letters only, first letter must be capital" required/>
        </label>
      </div>
      <label className="contact_form_row">
        Email <input type="text" name="email" value={formData.email} onChange={handleChange} placeholder="Your email.." pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$" title="Email should be in valid format(contains @ etc..)" required/>
      </label>
      <label className="contact_form_row">
        Message <textarea name="message" value={formData.message} onChange={handleChange} cols="40" rows="5" className="message" placeholder="Write something.." maxLength="300" title="Max length of the message is 300 characters" required></textarea>
      </label>
      <label className="contact_form_row">
      <p>File</p>
        <span className="select-file-button" onClick={handleFileButtonClick}>Select File</span>
        <input type="file" className="file-input" name="attachment" id="attachment" onChange={handleFileChange} hidden/>
        <span className="file-name-text"><p>No file selected</p></span>
      </label>
      <div className="form_but_row"><input type="submit" name="send_message" value="Send form" className="nav_but"/></div>
    </form>
  );
};

export default ContactForm;
