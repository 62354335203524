import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ChatIcon from '@mui/icons-material/Chat';
import Skill from "./Skill";
import CodeIcon from '@mui/icons-material/Code';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import GroupsIcon from '@mui/icons-material/Groups';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';

const Skills = () => {

    const title1 = 'Soft Skills and Communication';
    const title2 = 'Backend Development';
    const title3 = 'User Experience and Interface Design';
    const title4 = 'Data analysis and Data science';
    const title5 = 'Project Management and Collaboration';
    const title6 = 'Business Process Optimization and Automation';

    const techs1 = ['English B2', 'Czech B2', 'Russian C2', 'Spanish A1'];
    const techs2 = ['Java, JUnit, Selenium', 'Python, DJango', 'C/C++', 'JavaScript', 'PHP', 'SQL, PostgreSQL, MySQL'];
    const techs3 = ['HTML, CSS', 'JavaScript, React', 'Figma', 'Enterprise Architect', 'GIMP, Inscape'];
    const techs4 = ['Python, Pandas', 'MS Excel', 'PowerBI, Tableau', 'SQL, PostgreSQL, MySQL'];
    const techs5 = ['Microsoft Teams', 'Atlassian: Jira, Confluence', 'Slack', 'Git (Hub/Lab)'];
    const techs6 = ['Enterprise Architect', 'BPMN.io', 'Google Drive', 'Adonis'];
// const techs1 = ['Java, JUnit', 'Python', 'C/C++', 'JavaScript, React', 'PHP', 'HTML, CSS', 'SQL, PostgreSQL, MySQL'];
    // const techs2 = ['Git (Hub/Lab)',, 'EA', 'Selenium', 'PowerBI, Tableau', 'Figma']; //  'Jira', 'JavaFX', 'Maven', 'Docker', 'Spring', 'Hibernate', 'C++'
    // const techs3 = ['English B2', 'Czech B2', 'Russian C2', 'Spanish A1']; // 'Spanish A1'
    const customTypographyTitle = { fontFamily: 'Quicksand', color: '#5bc8ff', fontSize: 'x-large', fontWeight: 'bolder' };
    const customTypographyTech = { fontFamily: 'Quicksand', color: '#333', fontSize: 'large' };

    return (
        
        <div className="skillsContent" id="skills">
            <h1>Skills</h1>
            <div>
      <Accordion expanded className="accordion">
        <AccordionSummary
          expandIcon={<ChatIcon className='accordion_icon' sx={{ transform: 'rotate(180deg)', color: '#222'}}/>}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography sx={customTypographyTitle}>Soft Skills and Communication</Typography>
        </AccordionSummary>
        <AccordionDetails className="accordion-panel">
          <Typography sx={customTypographyTech}>
          <li>Languages: English B2, Czech B2, Russian C2, Spanish A1</li>
          <li>Technical Documentation and Reporting: Google Drive, MS Office</li>
          
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded className="accordion">
        <AccordionSummary
          expandIcon={<CodeIcon className='accordion_icon' sx={{ transform: 'rotate(180deg)', color: '#222'}}/>}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography sx={customTypographyTitle}>Backend Development</Typography>
        </AccordionSummary>
        <AccordionDetails className="accordion-panel">
          <Typography sx={customTypographyTech}>
          <li>Languages: Java, Python, C/C++, PHP, JavaScript</li>
          <li>Frameworks and Tools: Django, JUnit, Selenium</li>
          <li>Databases: SQL, PostgreSQL, MySQL</li>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded className="accordion">
        <AccordionSummary
          expandIcon={<DesignServicesIcon className='accordion_icon' sx={{ transform: 'rotate(180deg)', color: '#222'}} />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography sx={customTypographyTitle}>Frontend Development and UX/UI</Typography>
        </AccordionSummary>
        <AccordionDetails className="accordion-panel">
          <Typography sx={customTypographyTech}>
          <li>Frontend Technologies: HTML, CSS, JavaScript, React</li>
          <li>Design and Prototyping Tools: Figma, GIMP, Inkscape, Enterprise Architect</li>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded className="accordion">
        <AccordionSummary
          expandIcon={<QueryStatsIcon className='accordion_icon' sx={{ transform: 'rotate(180deg)', color: '#222'}} />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography sx={customTypographyTitle}>Data Analysis and Data Science</Typography>
        </AccordionSummary>
        <AccordionDetails className="accordion-panel">
          <Typography sx={customTypographyTech}>
          <li>Programming and Libraries: Python (Pandas, Matplotlib, Statsmodels)</li>
          <li>Data Visualization: Power BI, Tableau</li>
          <li>Data Management: SQL, PostgreSQL, MySQL</li>
          <li>Spreadsheet Analysis: MS Excel</li>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded className="accordion">
        <AccordionSummary
          expandIcon={<GroupsIcon className='accordion_icon' sx={{ transform: 'rotate(180deg)', color: '#222'}} />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography sx={customTypographyTitle}>Project Management and Collaboration</Typography>
        </AccordionSummary>
        <AccordionDetails className="accordion-panel">
          <Typography sx={customTypographyTech}>
          <li>Collaboration Tools: Microsoft Teams, Slack</li>
          <li>Project Management: Atlassian (Jira, Confluence)</li>
          <li>Version Control: Git (Hub/Lab)</li>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded className="accordion">
        <AccordionSummary
          expandIcon={<SettingsSuggestIcon className='accordion_icon' sx={{ transform: 'rotate(180deg)', color: '#222'}} />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography sx={customTypographyTitle}>Business Process Optimization and Automation</Typography>
        </AccordionSummary>
        <AccordionDetails className="accordion-panel">
          <Typography sx={customTypographyTech}>
          <li>Modeling Tools: Enterprise Architect, BPMN.io, Adonis</li>
          <li>Business Process Analysis: Experience in analyzing and optimizing business processes</li>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
            {/* <div className="skills">
                <Skill title={title3} techs={techs3}/>
                <Skill title={title1} techs={techs1}/>
                <Skill title={title2} techs={techs2}/>
            </div>
            <div className="skills">
                <Skill title={title1} techs={techs1}/>
                <Skill title={title2} techs={techs2}/>
                <Skill title={title3} techs={techs3}/>
            </div>
            <div className="skills">
                <Skill title={title4} techs={techs4}/>
                <Skill title={title5} techs={techs5}/>
                <Skill title={title6} techs={techs6}/>
            </div> */}
        </div>
    );
}

export default Skills;
