import Navbar from './Navbar';
import Home from './Home';
import AboutMe from './AboutMe';
import { BrowserRouter } from 'react-router-dom';
import { HashLink as Link} from 'react-router-hash-link';
import Skills from './Skills';
import MyProjects from './MyProjects';
import Contacts from './Contacts';
import Footer from './Footer';
import OtherProjects from './OtherProjects';
import SoundGenerator from './SoundGenerator';

function App() {
  return (
    <BrowserRouter>
    <div className="App" id="app">
      <Navbar />
      {/* <SoundGenerator /> */}
      <Home />
      <AboutMe />
      <Skills />
      <MyProjects />
      <OtherProjects />
      <Contacts />
      <Footer/>
    
    </div>
    </BrowserRouter>
  );
}

export default App;
