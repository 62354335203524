import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faLinkedin,
    faFacebook,
    faGithub,
    faInstagram
  } from "@fortawesome/free-brands-svg-icons";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function SocialMedia() {
  const showToastMessage = () => {
    toast.info('Not available now', {
        position: toast.POSITION.BOTTOM_CENTER
    });
  };
  return (
    
    <div className="social-container">
      {/* href="https://linkedin.com/" */}
      <a onClick={showToastMessage} className="linkedin social">
        <FontAwesomeIcon className="fa-lg" icon={faLinkedin} />
      </a>
      {/* href="https://www.github.com/" */}
      <a href="https://github.com/mamaegeo/" className="github social">
        <FontAwesomeIcon className="fa-lg" icon={faGithub} />
      </a>
      {/* href="https://www.facebook.com/" */}
      <a onClick={showToastMessage} className="facebook social">
        <FontAwesomeIcon className="fa-lg" icon={faFacebook} />
      </a>
      {/* href="https://www.instagram.com/" */}
      <a onClick={showToastMessage} className="instagram social">
        <FontAwesomeIcon className="fa-lg" icon={faInstagram} />
      </a>

    </div>
  );
}