import SocailMedia from "./SocailMedia"
const Home = () => {
    return (
        <div className="home">
            <div className="description">
                <h1>Hello, I'm Georgii</h1>
                <p>And this is my personal resume website</p>
                <SocailMedia />
            </div>
        </div>
    );
}
export default Home;