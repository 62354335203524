import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faArrowUpRightFromSquare } from"@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const OtherProject = (props) => {
    const showToastMessage = () => {
        toast.info('Not available yet', {
            position: toast.POSITION.BOTTOM_CENTER
        });
    };
    const {name, tags, github, web} = props;
    return (
        <div className="other_project">
            <div className="projectName">
                <h2>{name}</h2>
                <p><span className="blue">Tags:</span> {tags}</p>
                <div className="links">
                {/* href={github}  */}
                <a href={github} target="_blank" alt="github" className="project_icon social">
                GitHub <FontAwesomeIcon  icon={faGithub} />
                </a>
                {/* href={web}  */}
                {/* onClick={showToastMessage} */}
                {web != 'none'? <a href={web} target="_blank" alt="web" className="project_icon social " >
                Link <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                </a>: null }
                </div>
            </div>
         </div>
    );
}
export default OtherProject;